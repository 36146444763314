/* stylelint-disable  */
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #0d6efd;
  --blue-gray: #304c59;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #d63384;
  --red: #e20613;
  --orange: #ee6e06;
  --yellow: #f0ad4e;
  --green: #60a500;
  --teal: #20c997;
  --cyan: #009ecc;
  --white: #fff;
  --gray: #6c7d84;
  --gray-dark: #223640;
  --primary: #e20613;
  --secondary: #304c59;
  --success: #60a500;
  --info: #009ecc;
  --warning: #ee6e06;
  --danger: #e20613;
  --font-family-sans-serif: "DIN Web Pro", "Helvetica Neue", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow-y: scroll;
  font-family: "DIN Web Pro", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #304c59;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  margin: 1.5rem 0;
  color: #f5f5f5;
  background-color: currentColor;
  border: 0;
  opacity: 1;
}

hr:not([size]) {
  height: 3px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2rem;
}

h2, .h2 {
  font-size: 1.5rem;
}

h3, .h3 {
  font-size: 1.375rem;
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 0.875rem;
}

h6, .h6 {
  font-size: 0.75rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #e20613;
  text-decoration: none;
}

a:hover {
  color: #e20613;
  text-decoration: underline;
}

a:not([href]), a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #304c59;
  border-radius: 0;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 500;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c7d84;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.1rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: textfield;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

mark, .mark {
  padding: 0.2em;
  color: #fff;
  background-color: #304c59;
}

progress {
  vertical-align: baseline;
}

::-webkit-datetime-edit {
  overflow: visible;
  line-height: 0;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

main {
  display: block;
}

[hidden] {
  display: none !important;
}

@font-face {
  font-family: "DIN Web Pro";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/DINWebPro.woff") format("woff");
}

@font-face {
  font-family: "DIN Web Pro";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/DINWebPro-Italic.woff") format("woff");
}

@font-face {
  font-family: "DIN Web Pro";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/DINWebPro-Medium.woff") format("woff");
}

@font-face {
  font-family: "DIN Web Pro";
  font-style: italic;
  font-weight: 500;
  src: url("../assets/fonts/DINWebPro-MediumItalic.woff") format("woff");
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #416474;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

ins {
  font-style: italic;
  text-decoration: none;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c7d84;
}

.icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  fill: currentColor;
}

a .icon,
button .icon {
  width: 0.8em;
  height: 0.8em;
  pointer-events: none;
}

a .icon {
  vertical-align: baseline;
}

button .icon {
  vertical-align: middle;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #304c59;
  vertical-align: top;
}

.table th,
.table td {
  padding: 0.5rem;
  border-bottom: 1px solid #dee2e6;
}

.table tbody {
  vertical-align: inherit;
}

.table thead th {
  vertical-align: bottom;
  border-bottom-color: #416474;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.25rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-striped th,
.table-striped td {
  border-bottom: none;
}

.table-hover tbody tr:hover {
  color: #304c59;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #f7b9bd;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #f07e84;
}

.table-hover .table-primary:hover {
  background-color: #f4a2a7;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #f4a2a7;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c5cdd1;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #93a2a9;
}

.table-hover .table-secondary:hover {
  background-color: #b7c1c6;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #b7c1c6;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d2e6b8;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #acd07a;
}

.table-hover .table-success:hover {
  background-color: #c6dfa5;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #c6dfa5;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8e4f1;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7acde4;
}

.table-hover .table-info:hover {
  background-color: #a3dced;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a3dced;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fad6b9;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f6b47e;
}

.table-hover .table-warning:hover {
  background-color: #f8c8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f8c8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f7b9bd;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f07e84;
}

.table-hover .table-danger:hover {
  background-color: #f4a2a7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f4a2a7;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #304c59;
  border-color: #3d6172;
}

.table .thead-light th {
  color: #416474;
  background-color: #f5f5f5;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #304c59;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #3d6172;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-control {
  display: block;
  width: 100%;
  min-height: calc( 1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #304c59;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #304c59;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #304c59;
  background-color: #fff;
  border-color: #6092a9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(48, 76, 89, 0.25);
}

.form-control::placeholder {
  color: #6c7d84;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f5f5f5;
  border-color: #6c7d84;
  opacity: 1;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #304c59;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc( 1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0;
}

.form-control-lg {
  min-height: calc( 1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0;
}

.form-control-color {
  max-width: 3rem;
  padding: 0.375rem;
}

.form-control-color::-moz-color-swatch {
  border-radius: 0;
}

.form-control-color::-webkit-color-swatch {
  border-radius: 0;
}

.form-select {
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 1rem;
  color: #304c59;
  vertical-align: middle;
  cursor: pointer;
}

.form-select .icon {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  width: 1em;
  height: 1em;
  pointer-events: none;
  transform: translate(0, -50%);
}

.form-select select {
  width: 100%;
  height: calc( 1.5em + 0.75rem + 2px);
  padding: 0.375rem calc(1.75rem + 1em) 0.375rem 0.75rem;
  font-size: inherit;
  font-weight: 400;
  line-height: 1.5;
  color: inherit;
  background: #fff;
  border: 1px solid #304c59;
  border-radius: 0;
  appearance: none;
}

.form-select select:focus {
  border-color: #6092a9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(48, 76, 89, 0.25);
}

.form-select select:focus::-ms-value {
  color: #304c59;
  background-color: #fff;
}

.form-select select[multiple], .form-select select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.form-select select:disabled {
  color: #6c7d84;
  background-color: #f5f5f5;
  border-color: #6c7d84;
}

.form-select select::-ms-expand {
  display: none;
}

.form-select select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #304c59;
}

.form-select-sm {
  font-size: 0.875rem;
}

.form-select-sm select {
  height: calc( 1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
}

.form-select-lg {
  font-size: 1.25rem;
}

.form-select-lg select {
  height: calc( 1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.25em;
  margin-bottom: 0.75rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.25em;
}

.form-check-input {
  width: 0.75em;
  height: 0.75em;
  margin-top: 0.375em;
  background-color: #fff;
  border: 1px solid #304c59;
  appearance: none;
}

.form-check-input[type="checkbox"] {
  border-radius: 0;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #6092a9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(48, 76, 89, 0.25);
}

.form-check-input:checked {
  background-color: #e20613;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 0.75em;
  border-color: #e20613;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M4 8.5L6.5 11l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
  background-size: 0.5em;
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #e20613;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M5 8h6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 0.75em;
  border-color: #e20613;
}

.form-check-input[disabled] {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label {
  opacity: 0.5;
}

.form-check-label {
  margin-bottom: 0;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  height: 1.25rem;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: calc(1.25rem - 2px);
  border-radius: 2em;
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2380bdff'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: right center;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.form-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc( 1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.form-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc( 1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.form-file-input:focus ~ .form-file-label {
  border-color: #6092a9;
  box-shadow: 0 0 0 0.2rem rgba(48, 76, 89, 0.25);
}

.form-file-input:focus-within ~ .form-file-label {
  border-color: #6092a9;
  box-shadow: 0 0 0 0.2rem rgba(48, 76, 89, 0.25);
}

.form-file-input[disabled] ~ .form-file-label .form-file-text {
  background-color: #f5f5f5;
  border-color: #6c7d84;
}

.form-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  height: calc( 1.5em + 0.75rem + 2px);
  border-color: #304c59;
  border-radius: 0;
}

.form-file-text {
  display: block;
  flex-grow: 1;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #304c59;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.form-file-button {
  display: block;
  flex-shrink: 0;
  padding: 0.375rem 0.75rem;
  margin-left: -1px;
  line-height: 1.5;
  color: #304c59;
  background-color: #f5f5f5;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.form-range {
  width: 100%;
  height: calc( 1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: none;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(48, 76, 89, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(48, 76, 89, 0.25);
}

.form-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(48, 76, 89, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #e20613;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #fc9ea4;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #e20613;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #fc9ea4;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #e20613;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-ms-thumb {
    transition: none;
  }
}

.form-range::-ms-thumb:active {
  background-color: #fc9ea4;
}

.form-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.form-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.form-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-track {
  cursor: default;
}

.form-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .form-select,
  .form-inline .form-select form {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-file {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .form-select,
.input-group > .form-control + .form-file,
.input-group > .form-select + .form-control,
.input-group > .form-select + .form-select,
.input-group > .form-select + .form-file,
.input-group > .form-file + .form-control,
.input-group > .form-file + .form-select,
.input-group > .form-file + .form-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-file .form-file-input:focus ~ .form-file-label {
  z-index: 3;
}

.input-group > .form-file .form-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .form-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .form-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-file {
  display: flex;
  align-items: center;
}

.input-group > .form-file:not(:last-child) .form-file-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-file:not(:first-child) .form-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #304c59;
  text-align: center;
  white-space: nowrap;
  background-color: #f5f5f5;
  border: 1px solid #304c59;
  border-radius: 0;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control {
  min-height: calc( 1.5em + 1rem + 2px);
}

.input-group-lg > .form-select {
  height: calc( 1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0;
}

.input-group-sm > .form-control {
  min-height: calc( 1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-select {
  height: calc( 1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #60a500;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(96, 165, 0, 0.9);
  border-radius: 0;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #60a500;
  padding-right: calc( 1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2360a500' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc( 0.375em + 0.1875rem) center;
  background-size: calc( 0.75em + 0.375rem) calc( 0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #60a500;
  box-shadow: 0 0 0 0.2rem rgba(96, 165, 0, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc( 1.5em + 0.75rem);
  background-position: top calc( 0.375em + 0.1875rem) right calc( 0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #60a500;
  padding-right: calc( (1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2360a500' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc( 0.75em + 0.375rem) calc( 0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #60a500;
  box-shadow: 0 0 0 0.2rem rgba(96, 165, 0, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #60a500;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #7ed800;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 165, 0, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #60a500;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .form-file-input:valid ~ .form-file-label, .form-file-input.is-valid ~ .form-file-label {
  border-color: #60a500;
}

.was-validated .form-file-input:valid:focus ~ .form-file-label, .form-file-input.is-valid:focus ~ .form-file-label {
  border-color: #60a500;
  box-shadow: 0 0 0 0.2rem rgba(96, 165, 0, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e20613;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(226, 6, 19, 0.9);
  border-radius: 0;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #e20613;
  padding-right: calc( 1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e20613' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e20613' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc( 0.375em + 0.1875rem) center;
  background-size: calc( 0.75em + 0.375rem) calc( 0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #e20613;
  box-shadow: 0 0 0 0.2rem rgba(226, 6, 19, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc( 1.5em + 0.75rem);
  background-position: top calc( 0.375em + 0.1875rem) right calc( 0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #e20613;
  padding-right: calc( (1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e20613' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e20613' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc( 0.75em + 0.375rem) calc( 0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #e20613;
  box-shadow: 0 0 0 0.2rem rgba(226, 6, 19, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #e20613;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #f9222f;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 6, 19, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e20613;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .form-file-input:invalid ~ .form-file-label, .form-file-input.is-invalid ~ .form-file-label {
  border-color: #e20613;
}

.was-validated .form-file-input:invalid:focus ~ .form-file-label, .form-file-input.is-invalid:focus ~ .form-file-label {
  border-color: #e20613;
  box-shadow: 0 0 0 0.2rem rgba(226, 6, 19, 0.25);
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #304c59;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #304c59;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(48, 76, 89, 0.25);
}

.btn.disabled, .btn[aria-disabled="true"], .btn:disabled {
  opacity: 0.65;
}

a.btn.disabled, a.btn[aria-disabled="true"],
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-filled-primary {
  color: #fff;
  background-color: #e20613;
  border-color: #e20613;
}

.btn-filled-primary:hover {
  color: #fff;
  background-color: #bd0510;
  border-color: #b0050f;
}

.btn-filled-primary:focus, .btn-filled-primary.focus {
  color: #fff;
  background-color: #bd0510;
  border-color: #b0050f;
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-filled-primary.disabled, .btn-filled-primary[aria-disabled="true"], .btn-filled-primary:disabled {
  color: #fff;
  background-color: #e20613;
  border-color: #e20613;
}

.btn-filled-primary:not(:disabled):not(.disabled):not([aria-disabled="true"]):active, .btn-filled-primary:not(:disabled):not(.disabled):not([aria-disabled="true"]).active, .btn-filled-primary:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show > .btn-filled-primary.dropdown-toggle {
  color: #fff;
  background-color: #b0050f;
  border-color: #a4040e;
}

.btn-filled-primary:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus, .btn-filled-primary:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus, .btn-filled-primary:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show > .btn-filled-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-filled-secondary {
  color: #fff;
  background-color: #304c59;
  border-color: #304c59;
}

.btn-filled-secondary:hover {
  color: #fff;
  background-color: #233740;
  border-color: #1e3038;
}

.btn-filled-secondary:focus, .btn-filled-secondary.focus {
  color: #fff;
  background-color: #233740;
  border-color: #1e3038;
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-filled-secondary.disabled, .btn-filled-secondary[aria-disabled="true"], .btn-filled-secondary:disabled {
  color: #fff;
  background-color: #304c59;
  border-color: #304c59;
}

.btn-filled-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"]):active, .btn-filled-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"]).active, .btn-filled-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show > .btn-filled-secondary.dropdown-toggle {
  color: #fff;
  background-color: #1e3038;
  border-color: #1a2930;
}

.btn-filled-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus, .btn-filled-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus, .btn-filled-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show > .btn-filled-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-filled-success {
  color: #fff;
  background-color: #60a500;
  border-color: #60a500;
}

.btn-filled-success:hover {
  color: #fff;
  background-color: #4a7f00;
  border-color: #427200;
}

.btn-filled-success:focus, .btn-filled-success.focus {
  color: #fff;
  background-color: #4a7f00;
  border-color: #427200;
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-filled-success.disabled, .btn-filled-success[aria-disabled="true"], .btn-filled-success:disabled {
  color: #fff;
  background-color: #60a500;
  border-color: #60a500;
}

.btn-filled-success:not(:disabled):not(.disabled):not([aria-disabled="true"]):active, .btn-filled-success:not(:disabled):not(.disabled):not([aria-disabled="true"]).active, .btn-filled-success:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show > .btn-filled-success.dropdown-toggle {
  color: #fff;
  background-color: #427200;
  border-color: #3b6500;
}

.btn-filled-success:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus, .btn-filled-success:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus, .btn-filled-success:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show > .btn-filled-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-filled-info {
  color: #fff;
  background-color: #009ecc;
  border-color: #009ecc;
}

.btn-filled-info:hover {
  color: #fff;
  background-color: #0080a6;
  border-color: #007799;
}

.btn-filled-info:focus, .btn-filled-info.focus {
  color: #fff;
  background-color: #0080a6;
  border-color: #007799;
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-filled-info.disabled, .btn-filled-info[aria-disabled="true"], .btn-filled-info:disabled {
  color: #fff;
  background-color: #009ecc;
  border-color: #009ecc;
}

.btn-filled-info:not(:disabled):not(.disabled):not([aria-disabled="true"]):active, .btn-filled-info:not(:disabled):not(.disabled):not([aria-disabled="true"]).active, .btn-filled-info:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show > .btn-filled-info.dropdown-toggle {
  color: #fff;
  background-color: #007799;
  border-color: #006d8c;
}

.btn-filled-info:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus, .btn-filled-info:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus, .btn-filled-info:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show > .btn-filled-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-filled-warning {
  color: #fff;
  background-color: #ee6e06;
  border-color: #ee6e06;
}

.btn-filled-warning:hover {
  color: #fff;
  background-color: #c95d05;
  border-color: #bc5705;
}

.btn-filled-warning:focus, .btn-filled-warning.focus {
  color: #fff;
  background-color: #c95d05;
  border-color: #bc5705;
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-filled-warning.disabled, .btn-filled-warning[aria-disabled="true"], .btn-filled-warning:disabled {
  color: #fff;
  background-color: #ee6e06;
  border-color: #ee6e06;
}

.btn-filled-warning:not(:disabled):not(.disabled):not([aria-disabled="true"]):active, .btn-filled-warning:not(:disabled):not(.disabled):not([aria-disabled="true"]).active, .btn-filled-warning:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show > .btn-filled-warning.dropdown-toggle {
  color: #fff;
  background-color: #bc5705;
  border-color: #b05104;
}

.btn-filled-warning:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus, .btn-filled-warning:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus, .btn-filled-warning:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show > .btn-filled-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-filled-danger {
  color: #fff;
  background-color: #e20613;
  border-color: #e20613;
}

.btn-filled-danger:hover {
  color: #fff;
  background-color: #bd0510;
  border-color: #b0050f;
}

.btn-filled-danger:focus, .btn-filled-danger.focus {
  color: #fff;
  background-color: #bd0510;
  border-color: #b0050f;
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-filled-danger.disabled, .btn-filled-danger[aria-disabled="true"], .btn-filled-danger:disabled {
  color: #fff;
  background-color: #e20613;
  border-color: #e20613;
}

.btn-filled-danger:not(:disabled):not(.disabled):not([aria-disabled="true"]):active, .btn-filled-danger:not(:disabled):not(.disabled):not([aria-disabled="true"]).active, .btn-filled-danger:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show > .btn-filled-danger.dropdown-toggle {
  color: #fff;
  background-color: #b0050f;
  border-color: #a4040e;
}

.btn-filled-danger:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus, .btn-filled-danger:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus, .btn-filled-danger:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show > .btn-filled-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-primary,
.btn-outline-primary {
  color: #e20613;
  border-color: #e20613;
}

.btn-primary:hover,
.btn-outline-primary:hover {
  color: #fff;
  background-color: #e20613;
  border-color: #e20613;
}

.btn-primary:focus, .btn-primary.focus,
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-primary.disabled, .btn-primary[aria-disabled="true"], .btn-primary:disabled,
.btn-outline-primary.disabled,
.btn-outline-primary[aria-disabled="true"],
.btn-outline-primary:disabled {
  color: #e20613;
  background-color: transparent;
}

.btn-primary:not(:disabled):not(.disabled):not([aria-disabled="true"]):active, .btn-primary:not(:disabled):not(.disabled):not([aria-disabled="true"]).active, .btn-primary:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show > .btn-primary.dropdown-toggle,
.btn-outline-primary:not(:disabled):not(.disabled):not([aria-disabled="true"]):active,
.btn-outline-primary:not(:disabled):not(.disabled):not([aria-disabled="true"]).active,
.btn-outline-primary:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show >
.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #e20613;
  border-color: #e20613;
}

.btn-primary:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus, .btn-primary:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus, .btn-primary:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show > .btn-primary.dropdown-toggle:focus,
.btn-outline-primary:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show >
.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-secondary,
.btn-outline-secondary {
  color: #304c59;
  border-color: #304c59;
}

.btn-secondary:hover,
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #304c59;
  border-color: #304c59;
}

.btn-secondary:focus, .btn-secondary.focus,
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-secondary.disabled, .btn-secondary[aria-disabled="true"], .btn-secondary:disabled,
.btn-outline-secondary.disabled,
.btn-outline-secondary[aria-disabled="true"],
.btn-outline-secondary:disabled {
  color: #304c59;
  background-color: transparent;
}

.btn-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"]):active, .btn-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"]).active, .btn-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show > .btn-secondary.dropdown-toggle,
.btn-outline-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"]):active,
.btn-outline-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"]).active,
.btn-outline-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show >
.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #304c59;
  border-color: #304c59;
}

.btn-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus, .btn-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus, .btn-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show > .btn-secondary.dropdown-toggle:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show >
.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-success,
.btn-outline-success {
  color: #60a500;
  border-color: #60a500;
}

.btn-success:hover,
.btn-outline-success:hover {
  color: #fff;
  background-color: #60a500;
  border-color: #60a500;
}

.btn-success:focus, .btn-success.focus,
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-success.disabled, .btn-success[aria-disabled="true"], .btn-success:disabled,
.btn-outline-success.disabled,
.btn-outline-success[aria-disabled="true"],
.btn-outline-success:disabled {
  color: #60a500;
  background-color: transparent;
}

.btn-success:not(:disabled):not(.disabled):not([aria-disabled="true"]):active, .btn-success:not(:disabled):not(.disabled):not([aria-disabled="true"]).active, .btn-success:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show > .btn-success.dropdown-toggle,
.btn-outline-success:not(:disabled):not(.disabled):not([aria-disabled="true"]):active,
.btn-outline-success:not(:disabled):not(.disabled):not([aria-disabled="true"]).active,
.btn-outline-success:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show >
.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #60a500;
  border-color: #60a500;
}

.btn-success:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus, .btn-success:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus, .btn-success:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show > .btn-success.dropdown-toggle:focus,
.btn-outline-success:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus,
.btn-outline-success:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show >
.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-info,
.btn-outline-info {
  color: #009ecc;
  border-color: #009ecc;
}

.btn-info:hover,
.btn-outline-info:hover {
  color: #fff;
  background-color: #009ecc;
  border-color: #009ecc;
}

.btn-info:focus, .btn-info.focus,
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-info.disabled, .btn-info[aria-disabled="true"], .btn-info:disabled,
.btn-outline-info.disabled,
.btn-outline-info[aria-disabled="true"],
.btn-outline-info:disabled {
  color: #009ecc;
  background-color: transparent;
}

.btn-info:not(:disabled):not(.disabled):not([aria-disabled="true"]):active, .btn-info:not(:disabled):not(.disabled):not([aria-disabled="true"]).active, .btn-info:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show > .btn-info.dropdown-toggle,
.btn-outline-info:not(:disabled):not(.disabled):not([aria-disabled="true"]):active,
.btn-outline-info:not(:disabled):not(.disabled):not([aria-disabled="true"]).active,
.btn-outline-info:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show >
.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #009ecc;
  border-color: #009ecc;
}

.btn-info:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus, .btn-info:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus, .btn-info:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show > .btn-info.dropdown-toggle:focus,
.btn-outline-info:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus,
.btn-outline-info:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show >
.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-warning,
.btn-outline-warning {
  color: #ee6e06;
  border-color: #ee6e06;
}

.btn-warning:hover,
.btn-outline-warning:hover {
  color: #fff;
  background-color: #ee6e06;
  border-color: #ee6e06;
}

.btn-warning:focus, .btn-warning.focus,
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-warning.disabled, .btn-warning[aria-disabled="true"], .btn-warning:disabled,
.btn-outline-warning.disabled,
.btn-outline-warning[aria-disabled="true"],
.btn-outline-warning:disabled {
  color: #ee6e06;
  background-color: transparent;
}

.btn-warning:not(:disabled):not(.disabled):not([aria-disabled="true"]):active, .btn-warning:not(:disabled):not(.disabled):not([aria-disabled="true"]).active, .btn-warning:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show > .btn-warning.dropdown-toggle,
.btn-outline-warning:not(:disabled):not(.disabled):not([aria-disabled="true"]):active,
.btn-outline-warning:not(:disabled):not(.disabled):not([aria-disabled="true"]).active,
.btn-outline-warning:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show >
.btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ee6e06;
  border-color: #ee6e06;
}

.btn-warning:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus, .btn-warning:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus, .btn-warning:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show > .btn-warning.dropdown-toggle:focus,
.btn-outline-warning:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show >
.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-danger,
.btn-outline-danger {
  color: #e20613;
  border-color: #e20613;
}

.btn-danger:hover,
.btn-outline-danger:hover {
  color: #fff;
  background-color: #e20613;
  border-color: #e20613;
}

.btn-danger:focus, .btn-danger.focus,
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-danger.disabled, .btn-danger[aria-disabled="true"], .btn-danger:disabled,
.btn-outline-danger.disabled,
.btn-outline-danger[aria-disabled="true"],
.btn-outline-danger:disabled {
  color: #e20613;
  background-color: transparent;
}

.btn-danger:not(:disabled):not(.disabled):not([aria-disabled="true"]):active, .btn-danger:not(:disabled):not(.disabled):not([aria-disabled="true"]).active, .btn-danger:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show > .btn-danger.dropdown-toggle,
.btn-outline-danger:not(:disabled):not(.disabled):not([aria-disabled="true"]):active,
.btn-outline-danger:not(:disabled):not(.disabled):not([aria-disabled="true"]).active,
.btn-outline-danger:not(:disabled):not(.disabled):not([aria-disabled="true"])[aria-current="page"],
.show >
.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e20613;
  border-color: #e20613;
}

.btn-danger:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus, .btn-danger:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus, .btn-danger:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show > .btn-danger.dropdown-toggle:focus,
.btn-outline-danger:not(:disabled):not(.disabled):not([aria-disabled="true"]):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):not([aria-disabled="true"]).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus[aria-current="page"],
.show >
.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 54, 64, 0.25);
}

.btn-link {
  font-weight: 400;
  color: #e20613;
  text-decoration: none;
}

.btn-link:hover {
  color: #e20613;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled, .btn-link[aria-disabled="true"] {
  color: #6c7d84;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  position: relative;
  white-space: nowrap;
}

.dropdown-toggle .icon {
  position: absolute;
  top: 50%;
  right: 0;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .dropdown-toggle .icon {
    transition: none;
  }
}

.dropdown-toggle .icon {
  transform: translate(0, -50%) rotate(0deg);
}

.dropdown-toggle[aria-expanded="true"] .icon {
  transform: translate(0, -50%) rotate(180deg);
}

.btn.dropdown-toggle {
  padding-right: calc(1.5rem + 0.8em);
}

.btn.dropdown-toggle .icon {
  right: 0.75rem;
}

.dropleft .btn.dropdown-toggle {
  padding-left: calc(1.5rem + 0.8em);
}

.dropleft .btn.dropdown-toggle .icon {
  left: 0.75rem;
}

.btn-lg.dropdown-toggle, .btn-group-lg > .dropdown-toggle.btn {
  padding-right: calc(2rem + 0.8em);
}

.btn-lg.dropdown-toggle .icon, .btn-group-lg > .dropdown-toggle.btn .icon {
  right: 1rem;
}

.btn-sm.dropdown-toggle, .btn-group-sm > .dropdown-toggle.btn {
  padding-right: calc(1rem + 0.8em);
}

.btn-sm.dropdown-toggle .icon, .btn-group-sm > .dropdown-toggle.btn .icon {
  right: 0.5rem;
}

.btn.dropdown-toggle-split,
.dropleft .btn.dropdown-toggle-split,
.btn-lg + .btn.dropdown-toggle-split,
.btn-group-lg > .btn + .btn.dropdown-toggle-split,
.dropleft .btn-lg + .btn.dropdown-toggle-split,
.dropleft .btn-group-lg > .btn + .btn.dropdown-toggle-split,
.btn-sm + .btn.dropdown-toggle-split,
.btn-group-sm > .btn + .btn.dropdown-toggle-split,
.dropleft .btn-sm + .btn.dropdown-toggle-split,
.dropleft .btn-group-sm > .btn + .btn.dropdown-toggle-split {
  padding-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0 0;
  margin: 0 0 0;
  font-size: 1rem;
  color: #304c59;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.dropup .dropdown-toggle .icon {
  transform: translate(0, -50%) rotate(180deg);
}

.dropup .dropdown-toggle[aria-expanded="true"] .icon {
  transform: translate(0, -50%) rotate(360deg);
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
}

.dropright .dropdown-toggle .icon {
  transform: translate(0, -50%) rotate(-90deg);
}

.dropright .dropdown-toggle[aria-expanded="true"] .icon {
  transform: translate(0, -50%) rotate(90deg);
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0;
}

.dropleft .dropdown-toggle .icon {
  transform: translate(0, -50%) rotate(90deg);
}

.dropleft .dropdown-toggle[aria-expanded="true"] .icon {
  transform: translate(0, -50%) rotate(270deg);
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f5f5f5;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  clear: both;
  font-weight: 400;
  color: #304c59;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:first-child {
  border-top-left-radius: calc( 0 - 1px);
  border-top-right-radius: calc( 0 - 1px);
}

.dropdown-item:last-child {
  border-bottom-right-radius: calc( 0 - 1px);
  border-bottom-left-radius: calc( 0 - 1px);
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  text-decoration: none;
  background-color: #e20613;
}

.dropdown-item.active, .dropdown-item[aria-current="page"], .dropdown-item:active {
  color: #e20613;
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item.disabled, .dropdown-item[aria-disabled="true"], .dropdown-item:disabled {
  color: #6c7d84;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c7d84;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.5rem 0.75rem;
  color: #304c59;
}

.dropdown-block .btn,
.dropdown-block .dropdown-menu {
  width: 100%;
}

.dropdown-block .btn {
  position: relative;
  text-align: left;
}

.dropdown-block .btn::after {
  position: absolute;
  right: 0.75rem;
  bottom: calc(50% - 0.3em);
  transform: translate(0, -50%);
}

.dropdown-block .btn[aria-expanded="true"]::after {
  transform: translate(0, -50%) rotate(-180deg);
}

.dropdown-block .dropdown-menu {
  min-width: 0;
}

.dropdown-push {
  transition: transform 0.25s ease-in-out;
  transform: translate3d(0, 0, 0);
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group > .btn[aria-current="page"],
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn[aria-current="page"] {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: 0;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  font-weight: 500;
  color: #304c59;
}

.nav-link--account > .icon-single-neutral {
  font-size: 1.6rem;
}

.nav-link--account:hover, .nav-link--account:focus {
  /* stylelint-disable declaration-no-important */
  text-decoration: none !important;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.active, .nav-link[aria-current="page"] {
  color: #e20613;
}

.nav-link.disabled, .nav-link[aria-disabled="true"] {
  color: #72858e;
  pointer-events: none;
  cursor: default;
}

.nav-link .icon-arrow-right {
  margin-left: 0.125em;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link .icon-arrow-right {
    transition: none;
  }
}

.nav-link[aria-expanded="true"] .icon.arrow-down-1 {
  transform: rotate(-180deg);
}

.nav-tabs {
  border-bottom: 1px solid #304c59;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #304c59;
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link[aria-disabled="true"] {
  color: #72858e;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-link[aria-current="page"],
.nav-tabs .nav-item.show .nav-link {
  color: #e20613;
  background-color: #fff;
  border-color: #304c59 #304c59 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0;
}

.nav-pills .nav-link.active, .nav-pills .nav-link[aria-current="page"],
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #e20613;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active, .tab-content > [aria-current="page"] {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar--site-header {
  padding: 1.625rem 0;
}

.navbar--site-header .navbar-nav .nav-link {
  white-space: nowrap;
}

.navbar--site-header .navbar-brand {
  flex-grow: 1;
  min-width: 7rem;
  overflow: hidden;
}

.navbar--site-header .form-select {
  color: inherit;
}

.navbar--site-header .form-select select {
  height: calc(2.75rem - 2px);
  color: inherit;
  background-color: transparent;
}

.navbar--site-main {
  padding: 0;
  font-size: 1.5rem;
}

.navbar--site-main .nav-item .nav-link {
  padding: 1.75rem 0;
}

.navbar--site-main .nav-item:first-child .nav-link {
  padding-left: 0;
}

.navbar--site-main__list a {
  color: #304c59;
}

.navbar--site-main .global-navbar > .active a, .navbar--site-main .global-navbar > [aria-current="page"] a {
  text-decoration: underline;
}

.navbar-brand {
  display: inline-flex;
  align-items: center;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  margin-right: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  font-weight: 500;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
  line-height: 1;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-nav--main > .dropdown {
  position: static;
}

.navbar-nav--main > .dropdown > .dropdown-toggle .icon {
  position: static;
  transform: rotate(0);
}

.navbar-nav--main > .dropdown > .dropdown-toggle[aria-expanded="true"] .icon {
  transform: rotate(-180deg);
}

.navbar-nav--main > .dropdown > .dropdown-menu {
  min-width: 100%;
  padding: 1rem 0;
  font-size: 1.25rem;
  border: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0 0;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  position: relative;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
}

.navbar-toggler-bar {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 100%;
  height: 0.25rem;
  background-color: #304c59;
  border-radius: 1px;
  transition: transform 0.25s;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler-bar {
    transition: none;
  }
}

.navbar-toggler-bar:nth-child(1) {
  transform: translate(0, -250%);
}

.navbar-toggler-bar:nth-child(2) {
  transform: translate(0, -50%);
}

.navbar-toggler-bar:nth-child(3) {
  transform: translate(0, 150%);
}

[aria-expanded="true"] .navbar-toggler-bar:nth-child(1) {
  transform: translate(0, -50%) rotate(45deg);
}

[aria-expanded="true"] .navbar-toggler-bar:nth-child(2) {
  display: none;
}

[aria-expanded="true"] .navbar-toggler-bar:nth-child(3) {
  transform: translate(0, -50%) rotate(-45deg);
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
    align-items: center;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
    align-items: center;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    align-items: center;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
    align-items: center;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
  align-items: center;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light {
  background: #f5f5f5;
}

.navbar-light .navbar-brand {
  color: #304c59;
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #e20613;
}

.navbar-light .navbar-nav .nav-link {
  color: #304c59;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #e20613;
}

.navbar-light .navbar-nav .nav-link.disabled, .navbar-light .navbar-nav .nav-link[aria-disabled="true"] {
  color: rgba(48, 76, 89, 0.7);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav [aria-current="page"] > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link[aria-current="page"] {
  color: #e20613;
}

.navbar-light .navbar-toggler {
  color: #304c59;
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-bar {
  background-color: #304c59;
}

.navbar-light .navbar-text {
  color: #304c59;
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: #e20613;
}

.navbar-dark {
  color: #fff;
  background: #304c59;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #fff;
  text-decoration: underline;
}

.navbar-dark .navbar-nav .nav-link.disabled, .navbar-dark .navbar-nav .nav-link[aria-disabled="true"] {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav [aria-current="page"] > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link[aria-current="page"] {
  color: #fff;
}


.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav [aria-current="page"] > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link[aria-current="page"] {
  text-decoration: underline;
}

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-bar {
  background-color: #fff;
}

.navbar-dark a,
.navbar-dark a:hover,
.navbar-dark a:focus {
  color: #fff;
}

.navbar-dark .card {
  color: #fff;
  border-color: #72858e;
}

.navbar-dark .detail-grid,
.navbar-dark .detail-grid .col {
  border-color: #72858e;
}

.navbar-dark .detail-grid a {
  line-height: 1.1;
  color: inherit;
  text-decoration: none;
  border-bottom: 1px dotted;
}

.navbar-dark .detail-grid a:hover, .navbar-dark .detail-grid a:focus {
  border-bottom-style: solid;
}

.navbar-brand-logo {
  width: 100%;
  min-width: 7rem;
  max-width: 11rem;
}

.navbar-brand-logo svg {
  width: 100%;
}

.navbar-brand-text {
  margin-left: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .navbar-brand-text {
    font-size: 1rem;
  }
}

.navbar-top-detail-col {
  padding: 2.5rem 1rem;
  border: 1px solid #72858e;
  border-width: 1px 1px 0 0;
}

.navbar-top-detail-col:last-of-type {
  border-right: 0;
}

.navbar-top-detail-col > *:first-child {
  margin-top: 0;
}

.navbar-top-detail-col > *:last-child {
  margin-top: 0;
}

.navbar-top-detail-col a {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px dotted;
}

.navbar-top-detail-col a:hover, .navbar-top-detail-col a:focus {
  border-bottom-style: solid;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card.border-0 .card-body {
  padding-right: 0;
  padding-left: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
}

.card-title + .card-text {
  margin-top: 0.5rem;
}

.card-subtitle {
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc( 0 - 1px) calc( 0 - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc( 0 - 1px) calc( 0 - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-tabs .nav-link.active, .card-header-tabs .nav-link[aria-current="page"] {
  background-color: transparent;
  border-bottom-color: transparent;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.625rem 1.25rem;
  background: rgba(81, 105, 116, 0.9);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc( 0 - 1px);
  border-top-right-radius: calc( 0 - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc( 0 - 1px);
  border-bottom-left-radius: calc( 0 - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
}

.breadcrumb-item + .breadcrumb-item {
  position: relative;
  padding-left: calc( 1rem + 0.55em);
}

.breadcrumb-item + .breadcrumb-item .icon {
  position: absolute;
  top: 50%;
  left: 0.5rem;
  width: 0.55em;
  height: 0.55em;
  transform: translate(0, -50%);
  fill: #304c59;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active, .breadcrumb-item[aria-current="page"] {
  color: #304c59;
}

.breadcrumb-item:first-child a {
  position: relative;
  display: block;
  width: 1em;
  height: 100%;
}

.breadcrumb-item:first-child a .icon {
  position: absolute;
  top: 40%;
  left: 0;
  width: 1em;
  height: 1em;
  transform: translate(0, -50%);
  fill: #e20613;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #e20613;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #e20613;
  text-decoration: none;
  background-color: #f5f5f5;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(48, 76, 89, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link, .page-item[aria-current="page"] .page-link {
  z-index: 3;
  color: #fff;
  background-color: #e20613;
  border-color: #e20613;
}

.page-item.disabled .page-link, .page-item[aria-disabled="true"] .page-link {
  color: #6c7d84;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 0.75em;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 3px solid transparent;
  border-radius: 0;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 400;
}

.alert-dismissible {
  padding-right: 3.5rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #304c59;
  background: #fff;
  border-color: #e20613;
}

.alert-primary .alert-link {
  color: #304c59;
  text-decoration: underline;
}

.alert-secondary {
  color: #304c59;
  background: #fff;
  border-color: #304c59;
}

.alert-secondary .alert-link {
  color: #304c59;
  text-decoration: underline;
}

.alert-success {
  color: #304c59;
  background: #fff;
  border-color: #60a500;
}

.alert-success .alert-link {
  color: #304c59;
  text-decoration: underline;
}

.alert-info {
  color: #304c59;
  background: #fff;
  border-color: #009ecc;
}

.alert-info .alert-link {
  color: #304c59;
  text-decoration: underline;
}

.alert-warning {
  color: #304c59;
  background: #fff;
  border-color: #ee6e06;
}

.alert-warning .alert-link {
  color: #304c59;
  text-decoration: underline;
}

.alert-danger {
  color: #304c59;
  background: #fff;
  border-color: #e20613;
}

.alert-danger .alert-link {
  color: #304c59;
  text-decoration: underline;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #f5f5f5;
  border-radius: 0;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #e20613;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #416474;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #416474;
  text-decoration: none;
  background-color: #f5f5f5;
}

.list-group-item-action:active {
  color: #304c59;
  background-color: #f5f5f5;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.list-group-item.disabled, .list-group-item[aria-disabled="true"], .list-group-item:disabled {
  color: #6c7d84;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active, .list-group-item[aria-current="page"] {
  z-index: 2;
  color: #fff;
  background-color: #e20613;
  border-color: #e20613;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active, .list-group-item + .list-group-item[aria-current="page"] {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active, .list-group-horizontal .list-group-item[aria-current="page"] {
  margin-top: 0;
}

.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal .list-group-item + .list-group-item.active, .list-group-horizontal .list-group-item + .list-group-item[aria-current="page"] {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item.active, .list-group-horizontal-sm .list-group-item[aria-current="page"] {
    margin-top: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item.active, .list-group-horizontal-sm .list-group-item + .list-group-item[aria-current="page"] {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md .list-group-item.active, .list-group-horizontal-md .list-group-item[aria-current="page"] {
    margin-top: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item.active, .list-group-horizontal-md .list-group-item + .list-group-item[aria-current="page"] {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item.active, .list-group-horizontal-lg .list-group-item[aria-current="page"] {
    margin-top: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item.active, .list-group-horizontal-lg .list-group-item + .list-group-item[aria-current="page"] {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item.active, .list-group-horizontal-xl .list-group-item[aria-current="page"] {
    margin-top: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item.active, .list-group-horizontal-xl .list-group-item + .list-group-item[aria-current="page"] {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #76030a;
  background-color: #f7b9bd;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #76030a;
  background-color: #f4a2a7;
}

.list-group-item-primary.list-group-item-action.active, .list-group-item-primary.list-group-item-action[aria-current="page"] {
  color: #fff;
  background-color: #76030a;
  border-color: #76030a;
}

.list-group-item-secondary {
  color: #19282e;
  background-color: #c5cdd1;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #19282e;
  background-color: #b7c1c6;
}

.list-group-item-secondary.list-group-item-action.active, .list-group-item-secondary.list-group-item-action[aria-current="page"] {
  color: #fff;
  background-color: #19282e;
  border-color: #19282e;
}

.list-group-item-success {
  color: #325600;
  background-color: #d2e6b8;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #325600;
  background-color: #c6dfa5;
}

.list-group-item-success.list-group-item-action.active, .list-group-item-success.list-group-item-action[aria-current="page"] {
  color: #fff;
  background-color: #325600;
  border-color: #325600;
}

.list-group-item-info {
  color: #00526a;
  background-color: #b8e4f1;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #00526a;
  background-color: #a3dced;
}

.list-group-item-info.list-group-item-action.active, .list-group-item-info.list-group-item-action[aria-current="page"] {
  color: #fff;
  background-color: #00526a;
  border-color: #00526a;
}

.list-group-item-warning {
  color: #7c3903;
  background-color: #fad6b9;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7c3903;
  background-color: #f8c8a1;
}

.list-group-item-warning.list-group-item-action.active, .list-group-item-warning.list-group-item-action[aria-current="page"] {
  color: #fff;
  background-color: #7c3903;
  border-color: #7c3903;
}

.list-group-item-danger {
  color: #76030a;
  background-color: #f7b9bd;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #76030a;
  background-color: #f4a2a7;
}

.list-group-item-danger.list-group-item-action.active, .list-group-item-danger.list-group-item-action[aria-current="page"] {
  color: #fff;
  background-color: #76030a;
  border-color: #76030a;
}

.link-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.link-list li,
.link-list a {
  padding: 0.25em 0;
}

.link-list-divided li {
  padding: 0.75rem 0;
  border-bottom: 2px dotted #f5f5f5;
}

.link-list-divided li:first-child {
  border-top: 2px dotted #f5f5f5;
}

.link-list-md {
  font-size: 1.125rem;
}

@media (min-width: 992px) {
  .link-list-md {
    font-size: 1.25rem;
  }
}

.link-list-inline {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.link-list-inline li {
  position: relative;
  display: inline-block;
  padding: 0 0.6em;
  margin-right: -0.25em;
}

.link-list-inline li:first-child {
  padding-left: 0;
}

.link-list-inline li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.link-list-inline li + li::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 0;
  height: 100%;
  content: "";
  border-right: 1px solid;
}

.close {
  display: block;
  min-width: 1.875rem;
  min-height: 1.875rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  color: inherit;
  text-shadow: none;
  opacity: 0.5;
}

.close:hover {
  color: inherit;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):not([aria-disabled="true"]):hover, .close:not(:disabled):not(.disabled):not([aria-disabled="true"]):focus {
  opacity: 0.75;
}

.close .icon {
  display: block;
  width: 1em;
  height: 1em;
  margin: 0 auto;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled, a.close[aria-disabled="true"] {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c7d84;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc( 0 - 1px);
  border-top-right-radius: calc( 0 - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc( 0 - 1px);
  border-bottom-left-radius: calc( 0 - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "DIN Web Pro", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .tooltip-arrow, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .tooltip-arrow, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "DIN Web Pro", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0;
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[x-placement^="top"] > .popover-arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[x-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[x-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .popover-arrow, .bs-popover-auto[x-placement^="right"] > .popover-arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0 0;
}

.bs-popover-right > .popover-arrow::before, .bs-popover-auto[x-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .popover-arrow::after, .bs-popover-auto[x-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .popover-arrow, .bs-popover-auto[x-placement^="left"] > .popover-arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0 0;
}

.bs-popover-left > .popover-arrow::before, .bs-popover-auto[x-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .popover-arrow::after, .bs-popover-auto[x-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc( 0 - 1px);
  border-top-right-radius: calc( 0 - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #304c59;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::before, .carousel-inner::after {
  display: table;
  content: " ";
}

.carousel-inner::after {
  clear: both;
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item[aria-current="page"],
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right,
.carousel-item-right[aria-current="page"] {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left,
.carousel-item-left[aria-current="page"] {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item[aria-current="page"],
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left, .carousel-fade .carousel-item-left[aria-current="page"],
.carousel-fade .active.carousel-item-right,
.carousel-fade .carousel-item-right[aria-current="page"] {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .carousel-item-left[aria-current="page"],
  .carousel-fade .active.carousel-item-right,
  .carousel-fade .carousel-item-right[aria-current="page"] {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active, .carousel-indicators [aria-current="page"] {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

/*
Diese Datei ist von node_modules/flatpickr/dist/flatpickr.css
kopiert und in eine scss Datei umgewandelt. Damit kann diese an die theme
Anforderung von WKO-DS angepasst werden.
*/
/* stylelint-disable */
.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 0;
  position: absolute;
  width: 307.875px;
  box-sizing: border-box;
  touch-action: manipulation;
  background: #fff;
  box-shadow: 1px 0 0 #f5f5f5, -1px 0 0 #f5f5f5, 0 1px 0 #f5f5f5, 0 -1px 0 #f5f5f5, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n + 1) .flatpickr-day.inRange:nth-child(7n + 7) {
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n + 2) .flatpickr-day.inRange:nth-child(7n + 1) {
  box-shadow: -2px 0 0 #f5f5f5, 5px 0 0 #f5f5f5;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #f5f5f5;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.arrowRight:before, .flatpickr-calendar.rightMost:after, .flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before, .flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #f5f5f5;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #f5f5f5;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: #304c59;
  fill: #304c59;
  height: 34px;
  line-height: 1;
  margin-top: 8px;
  text-align: center;
  position: relative;
  user-select: none;
  overflow: hidden;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: #304c59;
  fill: #304c59;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  /*
          /*rtl:begin:ignore*/
  /*
          */
  left: 0;
  /*
          /*rtl:end:ignore*/
  /*
          */
}

.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
            /*rtl:begin:ignore*/
  /*
            */
  left: 0;
  /*
            /*rtl:end:ignore*/
  /*
            */
  /*
            /*rtl:begin:ignore*/
  /*
            */
  right: 0;
  /*
            /*rtl:end:ignore*/
  /*
            */
}

.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
            /*rtl:begin:ignore*/
  /*
            */
  right: 0;
  /*
            /*rtl:end:ignore*/
  /*
            */
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #304c59;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #e20613;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  transition: fill 0.1s;
  fill: inherit;
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #304c59;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #304c59;
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled] {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: #304c59;
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  box-sizing: border-box;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 #f5f5f5;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 0;
  box-sizing: border-box;
  color: #304c59;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #f5f5f5;
  border-color: #f5f5f5;
}

.flatpickr-day.today {
  border-color: #304c59;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: #304c59;
  background: #304c59;
  color: #fff;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #e20613;
  box-shadow: none;
  color: #fff;
  border-color: #e20613;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 #e20613;
}

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #f5f5f5, 5px 0 0 #f5f5f5;
}

.flatpickr-day.flatpickr-disabled {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled:hover {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.notAllowed {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #e20613, 5px 0 0 #e20613;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 0 #f5f5f5;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #304c59;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #304c59;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #304c59;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #304c59;
  font-weight: bold;
  width: 2%;
  user-select: none;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #304c59;
  font-weight: bold;
  width: 2%;
  user-select: none;
  align-self: center;
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.clearfix::before, .clearfix::after {
  display: table;
  content: " ";
}

.clearfix::after {
  clear: both;
}

.link-primary {
  color: #e20613;
}

.link-primary:hover, .link-primary:focus {
  color: #97040d;
}

.link-secondary {
  color: #304c59;
}

.link-secondary:hover, .link-secondary:focus {
  color: #152227;
}

.link-success {
  color: #60a500;
}

.link-success:hover, .link-success:focus {
  color: #335900;
}

.link-info {
  color: #009ecc;
}

.link-info:hover, .link-info:focus {
  color: #006380;
}

.link-warning {
  color: #ee6e06;
}

.link-warning:hover, .link-warning:focus {
  color: #a34c04;
}

.link-danger {
  color: #e20613;
}

.link-danger:hover, .link-danger:focus {
  color: #97040d;
}

.embed-responsive {
  position: relative;
  width: 100%;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  @media (min-width: 576px) {
    .sticky-sm-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  @media (min-width: 768px) {
    .sticky-md-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  @media (min-width: 992px) {
    .sticky-lg-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  @media (min-width: 1200px) {
    .sticky-xl-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
}

.sr-only,
.sr-only-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #e20613 !important;
}

.border-secondary {
  border-color: #304c59 !important;
}

.border-success {
  border-color: #60a500 !important;
}

.border-info {
  border-color: #009ecc !important;
}

.border-warning {
  border-color: #ee6e06 !important;
}

.border-danger {
  border-color: #e20613 !important;
}

.border-white {
  border-color: #fff !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mr-n1 {
  margin-right: -0.25rem !important;
}

.mr-n2 {
  margin-right: -0.5rem !important;
}

.mr-n3 {
  margin-right: -1rem !important;
}

.mr-n4 {
  margin-right: -1.5rem !important;
}

.mr-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ml-n1 {
  margin-left: -0.25rem !important;
}

.ml-n2 {
  margin-left: -0.5rem !important;
}

.ml-n3 {
  margin-left: -1rem !important;
}

.ml-n4 {
  margin-left: -1.5rem !important;
}

.ml-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

.text-primary {
  color: #e20613 !important;
}

.text-secondary {
  color: #304c59 !important;
}

.text-success {
  color: #60a500 !important;
}

.text-info {
  color: #009ecc !important;
}

.text-warning {
  color: #ee6e06 !important;
}

.text-danger {
  color: #e20613 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #304c59 !important;
}

.text-muted {
  color: #6c7d84 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.bg-primary {
  background-color: #e20613 !important;
}

.bg-secondary {
  background-color: #304c59 !important;
}

.bg-success {
  background-color: #60a500 !important;
}

.bg-info {
  background-color: #009ecc !important;
}

.bg-warning {
  background-color: #ee6e06 !important;
}

.bg-danger {
  background-color: #e20613 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0) !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.font-italic {
  font-style: italic !important;
}

.text-break {
  overflow-wrap: break-word !important;
  word-break: break-word !important;
}

.font-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-sm {
  border-radius: 0 !important;
}

.rounded-lg {
  border-radius: 0 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mr-sm-3 {
    margin-right: 1rem !important;
  }
  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mr-sm-5 {
    margin-right: 3rem !important;
  }
  .mr-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ml-sm-3 {
    margin-left: 1rem !important;
  }
  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ml-sm-5 {
    margin-left: 3rem !important;
  }
  .ml-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mr-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mr-sm-n3 {
    margin-right: -1rem !important;
  }
  .mr-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mr-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ml-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ml-sm-n3 {
    margin-left: -1rem !important;
  }
  .ml-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ml-sm-n5 {
    margin-left: -3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .pr-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pr-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pr-sm-3 {
    padding-right: 1rem !important;
  }
  .pr-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pr-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .pl-sm-1 {
    padding-left: 0.25rem !important;
  }
  .pl-sm-2 {
    padding-left: 0.5rem !important;
  }
  .pl-sm-3 {
    padding-left: 1rem !important;
  }
  .pl-sm-4 {
    padding-left: 1.5rem !important;
  }
  .pl-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .text-sm-justify {
    text-align: justify !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .mr-md-0 {
    margin-right: 0 !important;
  }
  .mr-md-1 {
    margin-right: 0.25rem !important;
  }
  .mr-md-2 {
    margin-right: 0.5rem !important;
  }
  .mr-md-3 {
    margin-right: 1rem !important;
  }
  .mr-md-4 {
    margin-right: 1.5rem !important;
  }
  .mr-md-5 {
    margin-right: 3rem !important;
  }
  .mr-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-0 {
    margin-left: 0 !important;
  }
  .ml-md-1 {
    margin-left: 0.25rem !important;
  }
  .ml-md-2 {
    margin-left: 0.5rem !important;
  }
  .ml-md-3 {
    margin-left: 1rem !important;
  }
  .ml-md-4 {
    margin-left: 1.5rem !important;
  }
  .ml-md-5 {
    margin-left: 3rem !important;
  }
  .ml-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mr-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mr-md-n3 {
    margin-right: -1rem !important;
  }
  .mr-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mr-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ml-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ml-md-n3 {
    margin-left: -1rem !important;
  }
  .ml-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ml-md-n5 {
    margin-left: -3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-0 {
    padding-right: 0 !important;
  }
  .pr-md-1 {
    padding-right: 0.25rem !important;
  }
  .pr-md-2 {
    padding-right: 0.5rem !important;
  }
  .pr-md-3 {
    padding-right: 1rem !important;
  }
  .pr-md-4 {
    padding-right: 1.5rem !important;
  }
  .pr-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .pl-md-1 {
    padding-left: 0.25rem !important;
  }
  .pl-md-2 {
    padding-left: 0.5rem !important;
  }
  .pl-md-3 {
    padding-left: 1rem !important;
  }
  .pl-md-4 {
    padding-left: 1.5rem !important;
  }
  .pl-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .text-md-justify {
    text-align: justify !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-0 {
    margin-right: 0 !important;
  }
  .mr-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mr-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mr-lg-3 {
    margin-right: 1rem !important;
  }
  .mr-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mr-lg-5 {
    margin-right: 3rem !important;
  }
  .mr-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .ml-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ml-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ml-lg-3 {
    margin-left: 1rem !important;
  }
  .ml-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ml-lg-5 {
    margin-left: 3rem !important;
  }
  .ml-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mr-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mr-lg-n3 {
    margin-right: -1rem !important;
  }
  .mr-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mr-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ml-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ml-lg-n3 {
    margin-left: -1rem !important;
  }
  .ml-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ml-lg-n5 {
    margin-left: -3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-0 {
    padding-right: 0 !important;
  }
  .pr-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pr-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pr-lg-3 {
    padding-right: 1rem !important;
  }
  .pr-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pr-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-0 {
    padding-left: 0 !important;
  }
  .pl-lg-1 {
    padding-left: 0.25rem !important;
  }
  .pl-lg-2 {
    padding-left: 0.5rem !important;
  }
  .pl-lg-3 {
    padding-left: 1rem !important;
  }
  .pl-lg-4 {
    padding-left: 1.5rem !important;
  }
  .pl-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .text-lg-justify {
    text-align: justify !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-0 {
    margin-right: 0 !important;
  }
  .mr-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mr-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mr-xl-3 {
    margin-right: 1rem !important;
  }
  .mr-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mr-xl-5 {
    margin-right: 3rem !important;
  }
  .mr-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-0 {
    margin-left: 0 !important;
  }
  .ml-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ml-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ml-xl-3 {
    margin-left: 1rem !important;
  }
  .ml-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ml-xl-5 {
    margin-left: 3rem !important;
  }
  .ml-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mr-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mr-xl-n3 {
    margin-right: -1rem !important;
  }
  .mr-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mr-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ml-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ml-xl-n3 {
    margin-left: -1rem !important;
  }
  .ml-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ml-xl-n5 {
    margin-left: -3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-0 {
    padding-right: 0 !important;
  }
  .pr-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pr-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pr-xl-3 {
    padding-right: 1rem !important;
  }
  .pr-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pr-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-0 {
    padding-left: 0 !important;
  }
  .pl-xl-1 {
    padding-left: 0.25rem !important;
  }
  .pl-xl-2 {
    padding-left: 0.5rem !important;
  }
  .pl-xl-3 {
    padding-left: 1rem !important;
  }
  .pl-xl-4 {
    padding-left: 1.5rem !important;
  }
  .pl-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .text-xl-justify {
    text-align: justify !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

a {
  display: inline-block;
}

.link-with-icon-left,
.link-with-icon-right {
  display: inline-flex;
}

.link-with-icon-left .icon,
.link-with-icon-right .icon {
  flex-shrink: 0;
}

.link-with-icon-left .icon {
  margin: 0.25em 0.25em 0 -0.15em;
}

.link-with-icon-right .icon {
  margin: 0.25em -0.15em 0 0.25em;
}

.more-link {
  display: inline-block;
  margin-left: 0.5em;
}

.teaser-card, .teaser-card-major {
  color: #fff;
}

.teaser-card:hover, .teaser-card-major:hover,
.teaser-card:hover *,
.teaser-card-major:hover *, .teaser-card:focus, .teaser-card-major:focus,
.teaser-card:focus *,
.teaser-card-major:focus * {
  color: #fff;
  text-decoration: none;
}

.teaser-card:hover .more-link, .teaser-card-major:hover .more-link, .teaser-card:focus .more-link, .teaser-card-major:focus .more-link {
  text-decoration: underline;
}

.teaser-card:hover .card-img-overlay, .teaser-card-major:hover .card-img-overlay, .teaser-card:focus .card-img-overlay, .teaser-card-major:focus .card-img-overlay {
  background: rgba(81, 105, 116, 0.95);
}

.teaser-card .card-img-overlay, .teaser-card-major .card-img-overlay {
  top: auto;
}

@media (max-width: 1199.98px) {
  .teaser-card .card-img-overlay, .teaser-card-major .card-img-overlay {
    position: relative;
    background: #516974;
  }
}

.teaser-card .card-body, .teaser-card-major .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #516974;
}

.teaser-card .card-body .card-title, .teaser-card-major .card-body .card-title {
  margin: 0;
  font-size: 1.5rem;
}

.teaser-card .card-body .card-title + .card-text, .teaser-card-major .card-body .card-title + .card-text {
  margin-top: 0.75rem;
}

.teaser-card-major .card-img-overlay {
  background: rgba(226, 6, 19, 0.9);
}

@media (max-width: 1199.98px) {
  .teaser-card-major .card-img-overlay {
    background: #e20613;
  }
}

.teaser-card-major:hover .card-img-overlay, .teaser-card-major:focus .card-img-overlay {
  background: rgba(226, 6, 19, 0.95);
}

.teaser-card-major .card-body {
  background: #e20613;
}

.hero-card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  min-height: 100%;
}

.hero-card-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: transparent no-repeat center center / cover;
}

.hero-card-content {
  position: relative;
  z-index: 1;
  padding: 2.5rem 1rem 3.75rem;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .hero-card-content {
    width: 90%;
    padding: 6.25rem 0;
  }
}

.hero-card-header {
  margin-bottom: 0.5rem;
}

.hero-card-headline {
  margin: 0;
  font-size: calc( 30px + 40 * ((100vw - 320px) / 1120));
  font-weight: 500;
  line-height: 1.28;
}

.hero-card-subheadline {
  margin: 0;
  font-size: calc( 18px + 22 * ((100vw - 320px) / 1120));
  line-height: 1.6;
}

.hero-card-body {
  font-size: calc( 20px + 2 * ((100vw - 320px) / 1120));
}

.hero-card-body *:last-child {
  margin-bottom: 0;
}

.hero-card-footer {
  margin-top: 2em;
  font-size: calc( 20px + 2 * ((100vw - 320px) / 1120));
}

.hero-card-footer button {
  font-size: inherit;
}

.footer {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-top: 2.5rem;
  font-size: 0.875rem;
  color: #304c59;
  background-color: #f5f5f5;
}

.footer a {
  color: inherit;
}

.footer__meta-nav .link-list-inline {
  margin: 0.625rem 0;
  line-height: 1;
}

.footer__meta-nav .link-list-inline li::before {
  border-color: #223640;
}

.detail-grid {
  line-height: 2;
  border-top-style: solid;
  border-top-width: 1px;
}

.detail-grid .col {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.detail-grid .col + .col {
  border-left-style: solid;
  border-left-width: 1px;
}

.detail-grid .col *:first-child {
  margin-top: 0;
}

.detail-grid .col *:last-child {
  margin-bottom: 0;
}

.detail-grid-close-col {
  padding: 0;
}

.news__container {
  width: 100%;
}

.news__container .date {
  font-size: 0.875rem;
}

.news__container .category {
  font-size: 0.875rem;
  font-weight: 500;
}

.header {
  color: #fff;
  background: #304c59;
}

@media (max-width: 767.98px) {
  .header__navbar-toggle .icon-bar {
    width: 20px;
    height: 3px;
  }
}

@media (max-width: 767.98px) and (max-width: 767.98px) {
  .header__navbar-toggle .icon-bar {
    margin-top: 4px;
  }
}

@media (max-width: 767.98px) {
  .header__navbar-toggle .icon-bar:nth-child(2) {
    transform: rotate(45deg) translate3d(6px, 7px, 0);
  }
  .header__navbar-toggle .icon-bar:nth-child(3) {
    transform: rotate(-45deg) translate3d(-1px, 0, 0);
  }
}

@media (max-width: 767.98px) and (max-width: 767.98px) {
  .header__navbar-toggle .icon-bar:nth-child(3) {
    transform: rotate(-45deg) translate3d(-2px, 0, 0);
  }
}

@media (max-width: 767.98px) {
  .header__navbar-toggle .icon-bar:last-child {
    visibility: hidden;
  }
}

.header__navbar-toggle .icon-bar + .icon-bar {
  margin-top: 5px;
}

.header__navbar-toggle .collapsed .icon-bar:last-child {
  visibility: visible;
}

.header__navbar-toggle .collapsed .icon-bar:nth-child(2) {
  transform: rotate(0deg) translate3d(0, 0, 0);
}

.header__navbar-toggle .collapsed .icon-bar:nth-child(3) {
  transform: rotate(0deg) translate3d(0, 0, 0);
}

.header__right {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
}

.header__right .icon-user {
  width: 23px;
  height: 23px;
}

.header__right a .mein-wko {
  display: block;
  height: 23px;
  padding: 0 0 0 15px;
}

.header__right a .mein-wko .icon-arrow-down {
  position: relative;
  width: 0.85em;
  height: 0.85em;
  transition: transform 0.25s;
  transform: rotate(180deg);
}

.header__right a .mein-wko .icon-arrow-down.collapsed {
  transform: rotate(0deg);
}

@media (max-width: 767px) {
  .header__right a .mein-wko {
    height: 20px;
    font-size: 13px;
  }
  .header__right a .mein-wko .icon-user {
    width: 20px;
    height: 20px;
  }
}

.header__right a .global-link {
  padding-top: 3px;
  padding-left: 0;
  font-weight: 500;
}

.header__right a span:not(.sr-only) {
  display: inline-block;
}

.header__right a:hover, .header__right a:focus {
  text-decoration: none;
}

.header__right a:hover span, .header__right a:focus span {
  text-decoration: underline;
}

@media (min-width: 576px) {
  .header__right {
    height: 43px;
  }
}

@media (max-width: 575.98px) {
  .header__right {
    padding-left: 0;
  }
}

.header__content {
  position: relative;
  padding-top: 26px;
  padding-bottom: 26px;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .header__content {
    padding-top: 13px;
    padding-bottom: 16px;
  }
}

.header__content__flex {
  display: flex;
  align-items: center;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .header__content__flex {
    margin-bottom: 10px;
  }
}

@media (max-width: 767.98px) {
  .header__content__flex {
    margin-bottom: 0;
  }
}

.header__content__flex .header__areas {
  margin-left: 10px;
}

@media (max-width: 575.98px) {
  .header__content__flex .header__areas {
    margin-left: auto;
  }
}

.header__claim {
  position: relative;
  bottom: -2px;
  display: inline-block;
  margin-right: 10px;
  font-size: 24px;
  font-weight: 500;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .header__claim {
    font-size: 17px;
  }
}

.header .link-list li {
  padding: 0;
}

.header .link-list li a {
  display: block;
  padding: 5px;
  margin-left: -5px;
}

.header .link-list li a:hover, .header .link-list li a:focus {
  text-decoration: none;
  background: #6c7d84;
}

.header a {
  color: #fff;
}

.header a:hover, .header a:focus {
  color: #fff;
  text-decoration: underline;
}

.header a .active, .header a [aria-current="page"] {
  padding: 5px;
  font-weight: bolder;
  color: #c5c5c5;
}

.header a .description {
  font-weight: 400;
}

.header__logo {
  display: inline-block;
  max-width: 40%;
  margin-right: 20px;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .header__logo {
    margin-right: 10px;
  }
}

.header__logo a {
  display: inline-block;
  vertical-align: middle;
}

.header__logo img {
  max-width: 100%;
  height: auto;
  max-height: 43px;
}

@media (max-width: 767.98px) {
  .header__logo img {
    width: 100%;
    max-height: 20px;
  }
}

@media (max-width: 575.98px) {
  .header__logo img {
    max-width: unset;
  }
}

.header__logo img__area {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 24%;
  padding: 0;
  font-size: 22px;
  line-height: 50px;
  color: #304c59;
  text-align: center;
  background: #fff;
}

.header__logo__areas {
  position: relative;
  display: inline-block;
  min-width: 130px;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .header__logo__areas {
    float: right;
  }
}

@media (max-width: 575.98px) {
  .header__logo__areas {
    margin-right: -10px;
  }
}

.header__logo__areas .form-control-select {
  background: #304c59;
}

.header__logo__areas .form-control-select .form-control {
  padding-right: 45px;
  color: #fff;
  border-color: #0d6efd;
}

.header__logo__areas .form-control-select .form-control option {
  color: #304c59;
  background: #fff;
  border-right: 1px solid #0d6efd;
}

.header__logo__areas .form-control-select .icon {
  background-color: #304c59;
}

@media (max-width: 575.98px) {
  .header__logo__areas .form-control-select {
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
  }
  .header__logo__areas .form-control-select .form-control {
    min-width: 140px;
    height: 24px;
    padding: 3px;
    font-size: 14px;
  }
  .header__logo__areas .form-control-select .icon {
    top: -5px;
    right: 3px;
    padding: 8px;
    font-size: 75%;
  }
}

.header__logo .social-links li:nth-child(5), .header__logo .social-links li:nth-child(6), .header__logo .social-links li:nth-child(7), .header__logo .social-links li:nth-child(8) {
  display: none;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .header__logo .social-links li:nth-child(5) {
    display: block;
  }
}

@media (max-width: 767.98px) {
  .header__logo__navbar-toggle {
    margin-left: 15px;
  }
}

@media (max-width: 575.98px) {
  .header__logo__navbar-toggle {
    margin-left: 5px;
  }
}

@media (max-width: 767.98px) {
  .header__logo .navbar-toggle {
    width: 20px;
    height: 20px;
  }
}

.top-section.header-new {
  min-height: 54px;
}

.top-section.header-new .global-panel .row > div {
  border-right: none;
}

@media (max-width: 767px) {
  .top-section.header-new .global-panel__content {
    padding: 0;
  }
  .top-section.header-new .global-panel .row {
    padding: 15px 0 11px;
  }
}

.nav-section {
  position: static;
}

.navbar-main {
  min-height: 0;
}

.navbar-main .navbar-nav .dropdown a {
  padding-left: 25px;
}

.navbar-main .navbar-nav .dropdown.open {
  padding-left: 25px;
}

.navbar-main .navbar-nav .dropdown.open a {
  padding-left: 0;
}

/* Ende header-new */
[aria-expanded="false"] .show-if-expanded {
  display: none;
}

[aria-expanded="true"] .hide-if-expanded {
  display: none;
}

.site-main {
  min-height: 40vh;
}

.collapsing-col__header {
  position: relative;
  overflow: hidden;
}

.collapsing-col__link {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 100%;
  color: inherit;
  text-align: right;
}

@media (min-width: 576px) {
  .collapsing-col__link.collapse-link--at-xs {
    right: -100em;
  }
}

@media (min-width: 768px) {
  .collapsing-col__link.collapse-link--at-sm {
    right: -100em;
  }
}

@media (min-width: 992px) {
  .collapsing-col__link.collapse-link--at-md {
    right: -100em;
  }
}

.panel-group--global {
  margin: 0;
}

.panel-group--global .global-panel {
  margin: 0;
  background: transparent;
  border: 0;
  box-shadow: none;
}

.panel-group--global .global-panel .collapse {
  border-top: 1px solid transparent;
}

.panel-group--global .global-panel .collapse.in {
  border-top-color: #6c7d84;
}

.panel-group--global .global-panel .container,
.panel-group--global .global-panel .container-fluid,
.panel-group--global .global-panel .container-sm,
.panel-group--global .global-panel .container-md,
.panel-group--global .global-panel .container-lg,
.panel-group--global .global-panel .container-xl {
  position: relative;
}

.panel-group--global .global-panel .collapsing {
  border-top: 1px solid #6c7d84;
}

.panel-group--global .global-panel__content {
  margin: 40px 0;
}

@media (max-width: 767.98px) {
  .panel-group--global .global-panel__content {
    padding: 20px 0;
    margin: 0;
  }
  .panel-group--global .global-panel__content .link-list {
    margin-top: 0;
  }
}

.panel-group--global .global-panel__navlist {
  padding: 0;
  margin: 0;
}

.panel-group--global .global-panel__navlist > li {
  float: left;
}

.panel-group--global .global-panel__heading {
  font-size: 24px;
}

.panel-group--global .global-panel__heading--small {
  font-size: 1rem;
  color: #6c7d84;
}

@media (max-width: 767.98px) {
  .panel-group--global .global-panel__heading .text-right {
    margin-bottom: 0;
    text-align: left;
  }
}

.panel-group--global .global-panel p {
  margin-bottom: 50px;
  line-height: 2;
}

@media (max-width: 767.98px) {
  .panel-group--global .global-panel .row {
    display: block;
  }
}

.panel-group--global .global-panel .row > div {
  border-right: 1px solid #6c7d84;
}

@media (max-width: 767.98px) {
  .panel-group--global .global-panel .row > div {
    min-height: 100%;
    border-right: 0;
  }
}

.panel-group--global .global-panel .row > div:last-child {
  border-right: 0;
}

.collapse-link > .icon-arrow-down {
  transition: transform 0.25s;
  transform: rotate(180deg);
}

.collapse-link.collapsed > .icon-arrow-down {
  transform: rotate(0deg);
}

.collapse-close {
  position: absolute;
  top: 0;
  right: 5px;
  z-index: 10;
  width: 30px;
  height: 30px;
  padding: 5px;
  font-size: 18px;
  text-align: center;
}

.collapse-close:hover, .collapse-close:focus {
  color: #fff;
  background: transparent;
}

.panel-group .panel-body,
.panel-group .list-group {
  border-top: 0;
}

.panel-group .panel-title > a {
  color: #304c59;
}

.panel-group .panel-title > a:hover, .panel-group .panel-title > a:focus {
  color: #e20613;
  text-decoration: none;
}

.panel-group .panel-title > a::before {
  display: inline-block;
  width: 20px;
  text-align: center;
  content: "-";
}

.panel-group .panel-title > a .collapsed {
  color: #e20613;
}

.panel-group .panel-title > a .collapsed:hover, .panel-group .panel-title > a .collapsed:focus {
  color: #304c59;
  text-decoration: none;
}

.panel-group .panel-title > a .collapsed::before {
  content: "+";
}

.panel-group .panel {
  border-top: 1px dotted #6c7d84;
}

.panel-group .panel .panel-body {
  margin-bottom: 20px;
  border-top-style: solid;
  border-top-width: 2px;
}

.panel-group .panel .panel-body.panel-primary, .panel-group .panel .panel-body.panel-success {
  border-top-color: #304c59;
}

.panel-group .panel .panel-body.panel-danger {
  border-top-color: #e20613;
}

.panel-group .panel .panel-body.panel-warning {
  border-top-color: #ee6e06;
}

.panel-group .panel .panel-body.panel-information {
  border-top-color: #999;
}

.panel-group .panel .panel-body .panel-body {
  padding: 20px;
}

.panel-group .panel + .panel {
  margin-top: 0;
}

.panel-group .panel-body {
  padding: 0 0 21px 21px;
}

.close {
  color: #333;
  text-shadow: 0 0 0 #fff;
  filter: alpha(opacity=80);
  opacity: 0.8;
}

.close:hover, .close:focus {
  filter: alpha(opacity=100);
  opacity: 1;
}

.navbar-dark {
  background: #AEECE9;
  color: #304c59;
}

.navbar-dark a,
.navbar-dark a:hover,
.navbar-dark a:focus {
  color: #304c59;
}

.navbar-dark .navbar-nav .nav-link {
  color: #304c59;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #304c59;
}

.navbar-dark .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-dark .navbar-brand .navbar-brand-logo {
  width: unset;
  min-width: unset;
  max-width: unset;
  height: 43px;
}

.navbar-dark .navbar-brand .navbar-brand-text {
  color: #304c59;
  margin-left: 1.25rem;
}

.navbar-dark .navbar-toggler-bar {
  background-color: #304c59;
}

.navbar--site-main {
  font-size: 1.25rem;
}

.navbar--site-main .nav-item .nav-link {
  padding: 1.5rem 0;
}

.banner {
  position: relative;
}

h1, .h1 {
  position: absolute;
  top: 110px;
  right: 40px;
  font-size: 3rem;
  color: #e20613;
  text-align: right;
  width: 60%;
  text-transform: uppercase;
}

.banner figure img {
  width: 100%;
}

figure figcaption {
  font-size: 0.65rem;
}

.banner-overlay {
  position: absolute;
  bottom: 80px;
  right: 200px;
}

.site-main a {
  text-decoration: underline;
}

.einleitung {
  margin-top: 3rem;
}

.controls {
  margin: 2.5rem 0 1.5rem;
}

.controls .btn {
  margin-right: 0.75rem;
}

.hinweis {
  font-size: 0.8125rem;
  padding: 1.5rem 0;
}

.footer {
  background: #AEECE9;
  color: #223640;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.footer .link-list-inline {
  line-height: 1;
}

@media (max-width: 1199px) {
  h1, .h1 {
    top: 20px;
    width: 70%;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@media (max-width: 991px) {
  h1, .h1 {
    top: 15px;
    font-size: 2rem;
    width: 70%;
  }
  .banner-overlay {
    position: absolute;
    bottom: 40px;
    right: 100px;
    height: 180px;
  }
  .einleitung {
    margin-top: 2rem;
  }
}

@media (max-width: 767px) {
  .navbar--site-header {
    padding: 0.75rem 0;
  }
  .navbar-brand-logo {
    height: 20px;
  }
  .navbar-brand-text {
    margin-left: 0.625rem;
    overflow: unset;
    text-overflow: unset;
    white-space: unset;
  }
  .navbar-dark .navbar-brand .navbar-brand-text {
    margin-left: 0.5rem;
  }
  .navbar-brand-text span {
    display: block;
  }
  .navbar-dark .navbar-nav .nav-link {
    font-size: 0.8125rem;
  }
  .navbar--site-main .nav-item .nav-link {
    padding: 1rem 0;
  }
  .navbar-toggler-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
  .navbar-toggler-icon:focus {
    outline: 2px solid #FFF;
  }
  .navbar-toggler-bar {
    height: 0.1875rem;
    border-radius: 0;
  }
  .controls .btn {
    display: block;
    margin-bottom: 1rem;
  }
  h1, .h1 {
    font-size: 1.375rem;
    position: static;
    text-align: left;
    width: 100%;
  }
  .banner-overlay {
    bottom: unset;
    right: 40px;
    top: 40px;
    height: 160px;
  }
  .banner-overlay img {
    width: auto;
    height: 100%;
  }
  .einleitung {
    margin-top: 1.5rem;
  }
  .link-list-inline li {
    display: block;
    margin-bottom: 10px;
    padding-left: 0;
  }
  .link-list-inline li + li::before {
    border-right: none;
  }
  #main-footer nav {
    text-align: left !important;
  }
}

@media (max-width: 570px) {
  .banner {
    padding-left: 0;
    padding-right: 0;
  }
  .banner figure {
    margin-left: -15px;
    margin-right: -15px;
  }
  figure figcaption {
    margin-left: 4px;
  }
}

@media (max-width: 479px) {
  .banner-overlay {
    right: 20px;
    top: 20px;
    height: 130px;
  }
  .navbar-dark .navbar-brand .navbar-brand-text {
    font-size: 0.85rem;
  }
}

@media (max-width: 340px) {
  .banner-overlay {
    right: 20px;
    top: 20px;
    height: 110px;
  }
  .navbar-dark .navbar-brand .navbar-brand-logo {
    height: 38px;
  }
  .navbar-dark .navbar-brand .navbar-brand-text {
    font-size: 0.75rem;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button.collapsed {
  border-bottom-width: 0;
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-collapse {
  border: solid rgba(0, 0, 0, 0.125);
  border-width: 0 1px;
}

.accordion-body {
  padding: 1rem 1rem 0 2.5rem;
}

.accordion-flush .accordion-button {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item:first-of-type .accordion-button {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion-header {
  margin-top: 1rem;
}

.accordion-button {
  color: #304c59;
  background-color: #e6f9f8;
  border: none;
  border-bottom: 1px solid #223640;
  padding: 0.8rem 0.625rem 0.5rem 0.625rem;
}

.accordion-button:focus {
  border-color: #223640;
  box-shadow: 0 0 0 0.2rem rgba(48, 76, 89, 0.25);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  background-image: none;
  margin-left: 0;
}

.accordion-button::before {
  flex-shrink: 0;
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 0;
  margin-right: 0.5rem;
  margin-top: -4px;
  content: "";
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M12,17.25c-0.414,0-0.75-0.336-0.75-0.75v-3.75H7.5c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h3.75V7.5c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v3.75h3.75c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75h-3.75v3.75C12.75,16.914,12.414,17.25,12,17.25z"/></g></svg>');
  background-repeat: no-repeat;
  background-size: 1.2rem;
  transition: transform 0.2s ease-in-out;
}

.accordion-button.collapsed {
  border-bottom-width: 1px;
}

.accordion-button:not(.collapsed) {
  color: #e20613;
  background-color: #e6f9f8;
}

.accordion-button:not(.collapsed):before {
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path fill="D9000D" d="M7.5,12.75c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h9c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H7.5z"/></g></svg>');
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path fill="red" d="M7.5,12.75c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h9c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H7.5z"/></g></svg>');
}

.accordion-collapse {
  border: none;
}

.accordion-body {
  padding: 1rem 2.5rem 0 2.5rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/*# sourceMappingURL=main.css.map */