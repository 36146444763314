/* stylelint-disable  */
@import "variables";
@import "~@wko-inhouse/wko-ds";

//eigenes SCSS hier unten starten
$blue-light:  #AEECE9  !default;
$blue-lighter:#e6f9f8 !default;
$red:         #D9000D !default; 

.navbar-dark {
  background: $blue-light;
  color: $gray-800;
  a,
  a:hover,
  a:focus {
    color: $gray-800;
  }
  .navbar-nav .nav-link {
    color: $gray-800;
    &:hover, &:focus {
      color:$gray-800;
    }
  }
  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    .navbar-brand-logo {
      width: unset;
      min-width: unset;
      max-width: unset;
      height: 43px;
    }
    .navbar-brand-text {
      color: $gray-800;
      margin-left: 1.25rem;
    }
  }
}
.navbar-dark .navbar-toggler-bar {
  background-color: $gray-800;
}
.navbar--site-main {
  font-size: 1.25rem;
  .nav-item .nav-link {
    padding: 1.5rem 0;
  }
}
.banner {
  position: relative;
}
h1 {
  position: absolute;
  top: 110px;
  right: 40px;
  font-size: 3rem;
  color: $red;
  text-align: right;
  width: 60%;
  text-transform: uppercase;
}
.banner figure img {
  width: 100%;
}
figure figcaption {
  font-size: 0.65rem;
}
.banner-overlay {
  position: absolute;
  bottom: 80px;
  right: 200px;
}
.site-main 
a {
  text-decoration: underline;
}
.einleitung {
  margin-top: 3rem;
}
.controls {
  margin: 2.5rem 0 1.5rem;
}
.controls .btn {
  margin-right: 0.75rem;
}
.hinweis {
  font-size: 0.8125rem;
  padding: 1.5rem 0;
}
.footer {
  background: $blue-light;
  color: $gray-900;
  padding-top: 1rem;
  padding-bottom: 1rem;
  .link-list-inline {
    line-height: 1;
  }
}
@media (max-width: 1199px) {
  h1 {
    top: 20px;
    width: 70%;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (max-width: 991px) {
  h1 {
    top: 15px;
    font-size: 2rem;
    width: 70%;
  }
  .banner-overlay {
    position: absolute;
    bottom: 40px;
    right: 100px;
    height: 180px;
  }
  .einleitung {
    margin-top: 2rem;
  }
}
@media (max-width: 767px) {
  .navbar--site-header {
    padding: 0.75rem 0;
  }
  .navbar-brand-logo {
    height: 20px;
  }
  .navbar-brand-text {
    margin-left: 0.625rem;
    overflow: unset;
    text-overflow: unset;
    white-space: unset;
  }
  .navbar-dark .navbar-brand .navbar-brand-text {
    margin-left: 0.5rem;
  }
  .navbar-brand-text span {
    display: block;
  }
  .navbar-dark .navbar-nav .nav-link {
    font-size: 0.8125rem;
  }
  .navbar--site-main .nav-item .nav-link {
    padding: 1rem 0;
  }
  .navbar-toggler-icon {
    width: 1.25rem;
    height: 1.25rem;
    &:focus {
      outline: 2px solid #FFF;
    }
  }
  .navbar-toggler-bar {
    height: 0.1875rem;
    border-radius: 0;
  }
  .controls .btn {
    display: block;
    margin-bottom: 1rem;
  }
  h1 {
    font-size: 1.375rem;
    position: static;
    text-align: left;
    width: 100%;
  }
  .banner-overlay {
    bottom: unset;
    right: 40px;
    top: 40px;
    height: 160px;
  }
  .banner-overlay img {
    width: auto;
    height: 100%;
  }
  .einleitung {
    margin-top: 1.5rem;
  }
  .link-list-inline li {
    display: block;
    margin-bottom: 10px;
    padding-left: 0;
    + li::before {
      border-right: none;
    }
  }
  #main-footer nav {
    text-align: left !important;
  }
}
@media (max-width: 570px) {
  .banner {
    padding-left: 0;
    padding-right: 0;
}
.banner figure {
  margin-left: -15px;
  margin-right: -15px;
}
  figure figcaption {
    margin-left: 4px;
  }
}
@media (max-width: 479px) {
  .banner-overlay {
    right: 20px;
    top: 20px;
    height: 130px;
  }
  .navbar-dark .navbar-brand .navbar-brand-text {
    font-size: 0.85rem;
  }
}
@media (max-width: 340px) {
  .banner-overlay {
    right: 20px;
    top: 20px;
    height: 110px;
  }
  .navbar-dark .navbar-brand .navbar-brand-logo {
    height: 38px;
  }
  .navbar-dark .navbar-brand .navbar-brand-text {
    font-size: 0.75rem;
  }
}

// base accordion
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button.collapsed {
  border-bottom-width: 0;
}
.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.accordion-header {
  margin-bottom: 0;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-collapse {
  border: solid rgba(0, 0, 0, 0.125);
  border-width: 0 1px;
}
.accordion-body {
  padding: 1rem 1rem 0 2.5rem;
}
.accordion-flush .accordion-button {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item:first-of-type .accordion-button {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

// custom accordion
.accordion-header {
  margin-top: 1rem;
}
.accordion-button {
  color: $gray-800;
  background-color: $blue-lighter;
  border: none;
  border-bottom: 1px solid $gray-900;
  padding: 0.8rem 0.625rem 0.5rem 0.625rem;
}
.accordion-button:focus {
  border-color: #223640;
  box-shadow: 0 0 0 0.2rem rgba(48, 76, 89, 0.25);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-button::after,
.accordion-button:not(.collapsed)::after  {
  background-image: none;
  margin-left: 0;
}
.accordion-button::before {
  flex-shrink: 0;
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 0;
  margin-right: 0.5rem;
  margin-top: -4px;
  content: "";
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M12,17.25c-0.414,0-0.75-0.336-0.75-0.75v-3.75H7.5c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h3.75V7.5c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v3.75h3.75c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75h-3.75v3.75C12.75,16.914,12.414,17.25,12,17.25z"/></g></svg>');
  background-repeat: no-repeat;
  background-size: 1.2rem;
  transition: transform 0.2s ease-in-out;
}
.accordion-button.collapsed {
  border-bottom-width: 1px;
}
@function svg-icon-minus($farbe) {
  @return url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path fill="'+$farbe+'" d="M7.5,12.75c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h9c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H7.5z"/></g></svg>');
}
.accordion-button:not(.collapsed) {
  color: $red;
  background-color: $blue-lighter;
  &:before {
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path fill="D9000D" d="M7.5,12.75c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h9c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H7.5z"/></g></svg>');  
    background-image: svg-icon-minus(red);
  }
}
.accordion-collapse {
  border: none;
}
.accordion-body {
  padding: 1rem 2.5rem 0 2.5rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}


